<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            required: true,
        },
    },
    components: {
    },
    data() {
        return {
            pdfHeight: window.innerHeight * 0.6,
            status: false,
        }
    },
    methods: {
        openPDPA() {
            window.open('https://v2vservice.com/thaimed-pdpa.pdf?v='+Date.now(), '_blank');
            return;
        }
    },
}
</script>

<template>
    <b-modal size="lg" centered v-model="isShow" title="PDPA นโยบายคุ้มครองข้อมูลส่วนบุคคล" no-close-on-esc
        no-close-on-backdrop hide-header-close>
        <b-button lg="5" varient="primary" @click="openPDPA">รับทราบเอกสาร นโยบายการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)
            มูลนิธิรวมพัฒน์</b-button>
        <!-- <embed src="https://v2vservice.com/thaimed-pdpa.pdf" frameborder="0" width="100%" :height="pdfHeight" /> -->
        <b-form-checkbox size="xl" v-model="status" name="pdpa" value="accepted" unchecked-value="not_accepted" class="my-2">
            <span class="ms-1">ข้าพเจ้าได้อ่านเอกสาร และยินยอมตามนโยบายการคุ้มครองข้อมูลส่วนบุคคล ของมูลนิธิรวมพัฒน์แล้ว</span>
        </b-form-checkbox>
        <template #modal-footer>
            <div class="w-100 d-block d-grid">
                <b-button variant="warning" size="xl" @click="$emit('close', true);" :disabled="status != 'accepted'">
                    ยอมรับ
                </b-button>
            </div>
        </template>
    </b-modal>
</template>